import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Donation from './Pages/Donation';
import DonationForm from './Pages/DonationForm';
import Navbar from './Pages/Navbar';
import Footer from './Pages/Footer';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Donation />} />
        <Route path="/donationform" element={<DonationForm />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
