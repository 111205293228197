import React, { useState } from 'react';
import '../Styles/Donation.css';
import { Link } from 'react-router-dom';

const Donation = () => {
    // State for Brick Donation
    const [brickCount, setBrickCount] = useState(1);
    const brickPrice = 50; // Price per brick
    const brickTotal = brickCount * brickPrice;

    // State for Square Foot Donation
    const [sqFootCount, setSqFootCount] = useState(1);
    const sqFootPrice = 500; // Price per square foot
    const sqFootTotal = sqFootCount * sqFootPrice;

    // Handle donation amount storage
    const handleDonateClick = (amount) => {
        window.localStorage.setItem("donationAmount", amount);
    };

    return (
        <div>
            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-overlay">
                    <h1 className="hero-heading">BECOME A SUDAMA SEVAK</h1>
                    <p className="hero-text">
                        Sudama Gave Poha to Krishna and Got many Things. Now Your Turn. By sponsoring the tile, your name will remain on the temple wall forever.
                    </p>
                    <button className="btn">Donate Now</button>
                </div>
            </div>

            {/* Donation Section */}
            <div className="details-bg">
                <h1 className="head">DONATE FOR TEMPLE CONSTRUCTION</h1>

                {/* Donation Cards */}
                <div className="cards-container">
                    {/* Card 1: Donate a Brick */}
                    <div className="card">
                        <img src='https://pbs.twimg.com/media/Ed-8qERVAAEY4UW.jpg:large' alt='brick images' />
                        <h2>Donate a Brick</h2>
                        <p>Brick donated by you will be a part of the temple forever</p>
                        {/* <p>Price per brick: ₹{brickPrice}</p> */}
                        <div className="donation-control">
                            <button onClick={() => setBrickCount(brickCount - 1)} disabled={brickCount <= 1}>-</button>
                            <span>{brickCount}</span>
                            <button onClick={() => setBrickCount(brickCount + 1)}>+</button>
                        </div>
                        <p>Total: ₹{brickTotal}</p>
                        {/* <p>Price per brick: ₹{brickPrice}</p> */}
                        <Link to="/donationform">
                            <button className='btn' onClick={() => handleDonateClick(brickTotal)}>Donate Now</button>
                        </Link>
                    </div>

                    {/* Card 2: Donate a Square Foot */}
                    <div className="card">
                        <img src='https://live.staticflickr.com/4065/4460663044_db831692f6_b.jpg' alt='sq feet images' />
                        <h2>Donate a Square Foot</h2>
                        <p>Donate per square feet space for temple construction</p>
                        {/* <p>Price per square foot: ₹{sqFootPrice}</p> */}
                        <div className="donation-control">
                            <button onClick={() => setSqFootCount(sqFootCount - 1)} disabled={sqFootCount <= 1}>-</button>
                            <span>{sqFootCount}</span>
                            <button onClick={() => setSqFootCount(sqFootCount + 1)}>+</button>
                        </div>
                        <p>Total: ₹{sqFootTotal}</p>
                        <Link to="/donationform">
                            <button className='btn' onClick={() => handleDonateClick(sqFootTotal)}>Donate Now</button>
                        </Link>
                    </div>
                </div>

                {/* Donation Information */}
                <div className="gh">80G AVAILABLE AS PER INCOME TAX ACT 1961 AND RULES MADE THEREUNDER</div>
                <div className="gh2">Income Tax Exemption (80-G) Number AIXSXX000000XX</div>

                <div className="details">
                    {/* Bank Details */}
                    <div className="bank">
                        <div className="line">Donation Through Bank</div>
                        <p>
                            <strong>Bank Name:</strong> Bandhan Bank <br />
                            <strong>Account Name:</strong> INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS <br />
                            <strong>Account Number:</strong> 50220019326471 <br />
                            <strong>IFSC Code:</strong> BDBL0002101
                        </p>
                    </div>

                    {/* Paytm Information */}
                    <div className="pytm">
                        <div className="line">Pay by PAYTM</div>
                        <p>
                            Gentle Request! While doing Paytm or Bank (NEFT/RTGS),<br />
                            please send us a screenshot on our WhatsApp No- <strong>+91-00000000000</strong>.<br />
                            You may also call on this number for other queries.
                        </p>
                    </div>

                    {/* QR Code for UPI */}
                    <div className="qr">
                        <div className="line">Pay by UPI</div>
                        <img className="qrimg" src="https://t.pimg.jp/064/305/428/1/64305428.jpg" alt="QR Code" />
                        <p><strong>IFSC Code:</strong> BDBL0002101</p>
                    </div>
                </div>

                {/* Benefits Section */}
                {/* <div className="container">
                <div>
                    <img src="https://www.thekrishnaites.org/wp-content/uploads/2021/12/DJ-Erics-Sm-1140x994.jpg" alt="sudama" />
                </div>
                <div className="benefits">
                    <span>BENEFITS OF SUDAMA SEWA</span>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae cum amet tenetur, ad ipsum nesciunt dicta!</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias culpa voluptate architecto ipsa fuga, eos nobis?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro dolore illum suscipit autem aspernatur molestias! Odio fuga iure hic.</p>
                </div>
            </div> */}

                {/* Construction Images Section */}
                <div className="const-bg">
                    <div className="head"><span>ISKCON ETAWAH - CONSTRUCTION</span></div>
                    <div className="img-const">
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                        <div className="const"><img src="https://www.iskconbangalore.org/wp-content/uploads/2015/11/t01-artisans-working-on-pillars-for-the-garbha-gudi.jpg" alt="construction" /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Donation;
