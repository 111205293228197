import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; 
import { collection, addDoc } from "firebase/firestore";
import '../Styles/DonationForm.css';

const DonationForm = () => {
    const [amount, setAmount] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        country: '',
        state: '',
        city: '',
        pinCode: '',
        panNumber: ''
    });

    const [successMessage, setSuccessMessage] = useState(""); // Added state to show success message

    useEffect(() => {
        window.scrollTo(0, 0);
        const savedAmount = window.localStorage.getItem("donationAmount");
        setAmount(savedAmount ? parseFloat(savedAmount) : 0);
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "donations"), {
                ...formData,
                amount,
                timestamp: new Date()
            });

            // Clear form fields after successful submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                country: '',
                state: '',
                city: '',
                pinCode: '',
                panNumber: ''
            });

            // Show success message
            setSuccessMessage("Donation submitted successfully!");

            // Remove success message after 3 seconds
            setTimeout(() => setSuccessMessage(""), 3000);
        } catch (error) {
            console.error("Error adding donation: ", error);
        }
    };

    return (
        <div className="donation-form">
            <img src="/sudama-krishna.jpg" alt="donation quote" />
            <h1>Help Us Build A Glorious Temple For Shri Radha Krishna</h1>
            <h3>Get Divine Blessings from Sri Sri Radha Madhav</h3>
            <p>Amount to be Donated: ₹{amount}</p>

            {/* Display success message if donation is submitted */}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <form onSubmit={handleSubmit}>
                <div className="form-container">
                    <div className="row">
                        <div className="form-group">
                            <label>Name:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Phone:</label>
                            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Country:</label>
                            <input type="text" name="country" value={formData.country} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label>State:</label>
                            <input type="text" name="state" value={formData.state} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>City:</label>
                            <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Pin Code:</label>
                            <input type="text" name="pinCode" value={formData.pinCode} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>PAN Number:</label>
                            <input type="text" name="panNumber" maxLength="10" value={formData.panNumber} onChange={handleChange} required />
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn">Confirm Donation</button>
                </div>
            </form>
        </div>
    );
};

export default DonationForm;
