import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="nav-container">
        <div className="logo">
          <img
            width="70"
            height="70"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXTMRzy7ByvZXQ4YhOX5_IdIT5Us5SOpw_RQ&s"
            alt="logo"
          />
        </div>
        <nav>
          {/* Navigation Links */}
          <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
            <li className="upp"><Link to="/">Home</Link></li>
            <li className="upp"><Link to="/">About Us</Link></li>
            <li className="upp"><Link to="/">Events</Link></li>
            <li className="upp"><Link to="/">Join Us</Link></li>
            <li className="upp"><Link to="/">Contact</Link></li>
            <li style={{ listStyle: "none" }}>
              <Link to="/">
                <button className="btn">Donate</button>
              </Link>
            </li>
          </ul>

          {/* Burger Menu Button */}
          <button className="burger" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
          </button>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;

