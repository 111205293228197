import '../Styles/Footer.css';
import React from 'react'

const Footer = () => {
  return (
    <div>
        <div className='footer'>
            Footer
        </div>
    </div>
  )
}

export default Footer;